import { AUTOTAG_TYPE } from './typings';

export const FILTER_AUTOTAG_TYPE: Record<AUTOTAG_TYPE, boolean> = {
  [AUTOTAG_TYPE.person]: false,
  [AUTOTAG_TYPE.organization]: false,
  [AUTOTAG_TYPE.media]: false,
  [AUTOTAG_TYPE.category]: false,
  [AUTOTAG_TYPE.games]: false,
  [AUTOTAG_TYPE.movie]: false,
  [AUTOTAG_TYPE.auto]: false,
  [AUTOTAG_TYPE.region]: true,
  [AUTOTAG_TYPE.city]: true,
  [AUTOTAG_TYPE.country]: true,
};

export const AUTO_TAG_TYPES = [
  AUTOTAG_TYPE.person,
  AUTOTAG_TYPE.organization,
  AUTOTAG_TYPE.media,
  AUTOTAG_TYPE.auto,
  AUTOTAG_TYPE.movie,
];
