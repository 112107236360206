export enum CURRENCY_CHAR_CODE {
  AMD = 'AMD',
  AED = 'AED',
  AUD = 'AUD',
  AZN = 'AZN',
  BGN = 'BGN',
  BYN = 'BYN',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  GEL = 'GEL',
  HKD = 'HKD',
  HUF = 'HUF',
  IDR = 'IDR',
  INR = 'INR',
  JPY = 'JPY',
  KGS = 'KGS',
  KRW = 'KRW',
  KWD = 'KWD',
  KZT = 'KZT',
  MDL = 'MDL',
  MXN = 'MXN',
  NOK = 'NOK',
  NZD = 'NZD',
  PLN = 'PLN',
  QAR = 'QAR',
  RON = 'RON',
  RSD = 'RSD',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TJS = 'TJS',
  TMT = 'TMT',
  TRY = 'TRY',
  UAH = 'UAH',
  USD = 'USD',
  UZS = 'UZS',
  VND = 'VND',
  XDR = 'XDR',
  ZAR = 'ZAR',
}

export enum FINANCE_TAG_WIDGET {
  Dollar = 'dollar',
  Evro = 'evro',
}

export enum PERIODS {
  day = 'day',
  week = 'week',
  month = 'month',
  quarter = 'quarter',
  year = 'year',
  all = 'all',
  threeDays = 'threeDays',
}

export enum TAG_TYPE {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  p = 'p',
}
