import { CLUSTER_TYPE, CLUSTER_TYPE_ALIAS } from './typings';

/**
 * Если в display_type кластера есть данная подстрока, то кластер относится к типу "статья"
 */
export const ARTICLE_TAG_ALIAS = 'article';

/**
 * Ограничитель количества новостей в ленте
 */
export const FEED_LENGTH = 5;

/**
 *  Если тип кластера статья то в бесконечке один кластер
 */
export const FEED_LENGTH_ARTICLE = 1;

/**
 * Ключевое слово с которого должно начинаться короткое описание кластера.
 * Если его нет, то короткое описание не отображаем.
 */
export const SHORT_BLOCK_SECRET_WORD = 's1ap7ha7boo7ych33ks';

type ClusterNameType = 'news' | 'photo' | 'video' | 'article' | 'test';

export const CLUSTER_NAME_TYPES: Record<CLUSTER_TYPE, ClusterNameType> = {
  [CLUSTER_TYPE.news]: 'news',
  [CLUSTER_TYPE.photo]: 'photo',
  [CLUSTER_TYPE.video]: 'video',
  [CLUSTER_TYPE.article]: 'article',
  [CLUSTER_TYPE.test]: 'test',
};

export const TAB_NAME_CLUSTER_TYPES: CLUSTER_TYPE_ALIAS[] = [
  CLUSTER_TYPE_ALIAS.article,
  CLUSTER_TYPE_ALIAS.news,
  CLUSTER_TYPE_ALIAS.test,
  CLUSTER_TYPE_ALIAS.photo,
  CLUSTER_TYPE_ALIAS.video,
];
