import { PROJECT_ALIASES, PROJECT_IDS } from './typings';

export const HEAD_DOMAIN = 'https://www.rambler.ru/';

export const PROJECT_ALIAS_BY_ID: Record<PROJECT_IDS, PROJECT_ALIASES> = {
  [PROJECT_IDS.Ai]: PROJECT_ALIASES.Ai,
  [PROJECT_IDS.Auto]: PROJECT_ALIASES.Auto,
  [PROJECT_IDS.Doctor]: PROJECT_ALIASES.Doctor,
  [PROJECT_IDS.Dom]: PROJECT_ALIASES.Dom,
  [PROJECT_IDS.Family]: PROJECT_ALIASES.Family,
  [PROJECT_IDS.Finance]: PROJECT_ALIASES.Finance,
  [PROJECT_IDS.Horoscopes]: PROJECT_ALIASES.Horoscopes,
  [PROJECT_IDS.Kino]: PROJECT_ALIASES.Kino,
  [PROJECT_IDS.Life]: PROJECT_ALIASES.Life,
  [PROJECT_IDS.News]: PROJECT_ALIASES.News,
  [PROJECT_IDS.Pro]: PROJECT_ALIASES.Pro,
  [PROJECT_IDS.Sci]: PROJECT_ALIASES.Sci,
  [PROJECT_IDS.Sport]: PROJECT_ALIASES.Sport,
  [PROJECT_IDS.Travel]: PROJECT_ALIASES.Travel,
  [PROJECT_IDS.Unknown]: PROJECT_ALIASES.Unknown,
  [PROJECT_IDS.Weekend]: PROJECT_ALIASES.Weekend,
  [PROJECT_IDS.Woman]: PROJECT_ALIASES.Woman,
};

export const PROJECTS_NAME_BY_ID: Record<PROJECT_IDS, string> = {
  [PROJECT_IDS.Ai]: 'Рамблер/искусственный интеллект',
  [PROJECT_IDS.Auto]: 'Рамблер/авто',
  [PROJECT_IDS.Doctor]: 'Рамблер/здоровье',
  [PROJECT_IDS.Dom]: 'Рамблер/дом и уют',
  [PROJECT_IDS.Family]: 'Рамблер/родители и дети',
  [PROJECT_IDS.Finance]: 'Рамблер/личные финансы',
  [PROJECT_IDS.Horoscopes]: '',
  [PROJECT_IDS.Kino]: 'Рамблер/кино',
  [PROJECT_IDS.Life]: 'Рамблер/жизненные ситуации',
  [PROJECT_IDS.News]: 'Рамблер/новости',
  [PROJECT_IDS.Pro]: 'Рамблер/карьера',
  [PROJECT_IDS.Sci]: 'Рамблер/технологии и тренды',
  [PROJECT_IDS.Sport]: 'Рамблер/спорт',
  [PROJECT_IDS.Travel]: 'Рамблер/путешествия',
  [PROJECT_IDS.Unknown]: '',
  [PROJECT_IDS.Weekend]: 'Рамблер/развлечения и отдых',
  [PROJECT_IDS.Woman]: 'Рамблер/женский',
};

export const PROJECTS_SHORT_NAME_BY_ID: Record<PROJECT_IDS, string> = {
  [PROJECT_IDS.Ai]: 'Искусственный интеллект',
  [PROJECT_IDS.Auto]: 'Aвто',
  [PROJECT_IDS.Doctor]: 'Здоровье',
  [PROJECT_IDS.Dom]: 'Дом и уют',
  [PROJECT_IDS.Family]: 'Родители и Дети',
  [PROJECT_IDS.Finance]: 'Личные финансы',
  [PROJECT_IDS.Horoscopes]: 'Гороскопы',
  [PROJECT_IDS.Kino]: 'Кино',
  [PROJECT_IDS.Life]: 'Жизненные ситуации',
  [PROJECT_IDS.News]: 'Все новости',
  [PROJECT_IDS.Pro]: 'Карьера',
  [PROJECT_IDS.Sci]: 'Технологии и тренды',
  [PROJECT_IDS.Sport]: 'Спорт',
  [PROJECT_IDS.Travel]: 'Путешествия',
  [PROJECT_IDS.Unknown]: '',
  [PROJECT_IDS.Weekend]: 'Развлечения и отдых',
  [PROJECT_IDS.Woman]: 'Женский',
};

export const LOGOS_WIDTH: Record<PROJECT_IDS, number> = {
  [PROJECT_IDS.Ai]: 0,
  [PROJECT_IDS.Auto]: 185,
  [PROJECT_IDS.Doctor]: 208,
  [PROJECT_IDS.Dom]: 0,
  [PROJECT_IDS.Family]: 0,
  [PROJECT_IDS.Finance]: 228,
  [PROJECT_IDS.Horoscopes]: 0,
  [PROJECT_IDS.Kino]: 191,
  [PROJECT_IDS.Life]: 0,
  [PROJECT_IDS.News]: 221,
  [PROJECT_IDS.Pro]: 0,
  [PROJECT_IDS.Sci]: 0,
  [PROJECT_IDS.Sport]: 198,
  [PROJECT_IDS.Travel]: 258,
  [PROJECT_IDS.Unknown]: 0,
  [PROJECT_IDS.Weekend]: 250,
  [PROJECT_IDS.Woman]: 223,
};

export const PROJECT_OG_IMG: Record<PROJECT_ALIASES, string> = {
  [PROJECT_ALIASES.Ai]:
    'https://news.rambler.ru/special/share-preview/snippet-ai.jpg',
  [PROJECT_ALIASES.Auto]:
    'https://news.rambler.ru/special/share-preview/snippet-auto.jpg',
  [PROJECT_ALIASES.Doctor]:
    'https://news.rambler.ru/special/share-preview/snippet-doctor.jpg',
  [PROJECT_ALIASES.Dom]:
    'https://news.rambler.ru/special/share-preview/snippet-dom.jpg',
  [PROJECT_ALIASES.Family]:
    'https://news.rambler.ru/special/share-preview/snippet-family.jpg',
  [PROJECT_ALIASES.Finance]:
    'https://news.rambler.ru/special/share-preview/snippet-finance.jpg',
  [PROJECT_ALIASES.Horoscopes]: '',
  [PROJECT_ALIASES.Kino]:
    'https://news.rambler.ru/special/share-preview/snippet-kino.jpg',
  [PROJECT_ALIASES.Life]:
    'https://news.rambler.ru/special/share-preview/snippet-life.jpg',
  [PROJECT_ALIASES.News]:
    'https://news.rambler.ru/special/share-preview/snippet-news.jpg',
  [PROJECT_ALIASES.Pro]:
    'https://news.rambler.ru/special/share-preview/snippet-pro.jpg',
  [PROJECT_ALIASES.Sci]:
    'https://news.rambler.ru/special/share-preview/snippet-sci.jpg',
  [PROJECT_ALIASES.Sport]:
    'https://news.rambler.ru/special/share-preview/snippet-sport.jpg',
  [PROJECT_ALIASES.Travel]:
    'https://news.rambler.ru/special/share-preview/snippet-travel.jpg',
  [PROJECT_ALIASES.Unknown]: '',
  [PROJECT_ALIASES.Weekend]:
    'https://news.rambler.ru/special/share-preview/snippet-weekend.jpg',
  [PROJECT_ALIASES.Woman]:
    'https://news.rambler.ru/special/share-preview/snippet-woman.jpg',
};

export const ID_PROJECT_BY_ALIAS: Record<PROJECT_ALIASES, PROJECT_IDS> = {
  [PROJECT_ALIASES.Ai]: PROJECT_IDS.Ai,
  [PROJECT_ALIASES.Auto]: PROJECT_IDS.Auto,
  [PROJECT_ALIASES.Doctor]: PROJECT_IDS.Doctor,
  [PROJECT_ALIASES.Dom]: PROJECT_IDS.Dom,
  [PROJECT_ALIASES.Family]: PROJECT_IDS.Family,
  [PROJECT_ALIASES.Finance]: PROJECT_IDS.Finance,
  [PROJECT_ALIASES.Horoscopes]: PROJECT_IDS.Horoscopes,
  [PROJECT_ALIASES.Kino]: PROJECT_IDS.Kino,
  [PROJECT_ALIASES.Life]: PROJECT_IDS.Life,
  [PROJECT_ALIASES.News]: PROJECT_IDS.News,
  [PROJECT_ALIASES.Pro]: PROJECT_IDS.Pro,
  [PROJECT_ALIASES.Sci]: PROJECT_IDS.Sci,
  [PROJECT_ALIASES.Sport]: PROJECT_IDS.Sport,
  [PROJECT_ALIASES.Travel]: PROJECT_IDS.Travel,
  [PROJECT_ALIASES.Unknown]: PROJECT_IDS.Unknown,
  [PROJECT_ALIASES.Weekend]: PROJECT_IDS.Weekend,
  [PROJECT_ALIASES.Woman]: PROJECT_IDS.Woman,
};
